.large-page {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 100%;
  justify-content: center;
}

.large-page-content {
  padding-left: calc(32px + 5%);
  padding-right: calc(32px + 5%);
  padding-top: 32px;
  padding-bottom: 32px;
  min-height: 100%;
  min-height: 100%;
}

.collapse-margin {
  margin-left: 10%;
  margin-right: 10%;
  min-height: 100%;
}

@media (max-width: 1200px) {
  .collapse-margin {
    margin-left: 2% !important;
    margin-right: 2% !important;
  }

  .large-page-content {
    padding-left: 5%;
    padding-right: 5%;
  }
}
