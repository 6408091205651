.page-row {
  height: calc(100% - var(--navbarHeight));
  margin-top: var(--navbarHeight);
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.page-col {
  height: 100%;
  overflow-y: auto;
}
